/* You can add global styles to this file, and also import other style files */
ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday{
    color: #5e72e4;
    padding-top: 10px;
    opacity: 0.8;
    font-style: normal !important;
    line-height: 20px;
    font-weight: 300;
    width: 36px;
    height: 36px;
    font-size: .875rem;
    transition: all .15s ease;
    text-align: center;
    border: none;
}